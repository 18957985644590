import React, { Component } from 'react';
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom";

import './dashboard.styles.css';

import Menu from '../menu/Menu';
import GeneralData from '../generalData/GeneralData';
import ResumenSeguimiento from '../acompanamiento/seguimiento/Resumen';
import ResumenSeguimientoNuevo from '../acompanamiento/seguimiento-nuevo/Resumen';

//Vacantes Relacionamiento - Seleccion.
import VacantesRS from '../relacionamiento/vacantes/Vacantes';

// Componentes de relacionamiento
import AliadosExternos from '../relacionamiento/aliadosExternos/AliadosExternos';
import Cronogramas from '../relacionamiento/cronogramas/Cronogramas';
import Registros from '../relacionamiento/cronogramas/registros/Registros';
import CronogramasAliados from '../relacionamiento/cronogramas/aliadosExternos/AliadosExternos';
import Vinculados from '../relacionamiento/cronogramas/vinculados/vinculados';
import Reingreso from '../relacionamiento/reingreso/Reingreso';
import Asesorias from '../asesorias/Asesorias';
import AddAsesoria from '../asesorias/addAsesoria/AddAsesoria';
import Ciudades from '../ciudades/Ciudades';
import AliadosFiltro from '../aliados/Aliados';
import AddCronogramaRegistros from '../relacionamiento/cronogramas/registros/addRegistro';
import AddCronogramaSeguimientos from '../relacionamiento/cronogramas/AddCalendar';
import AddEmpleado from '../asesorias/colaborador/Colaborador';
import AddJefe from '../asesorias/jefe/Jefe';
import HojaVida from '../hojaVida/viewHojaVida/ViewHojaVida';
import Eliminados from '../relacionamiento/eliminados/Eliminados';
import CustomerCities from '../clienteGestor/ciudades/Ciudades';
import CustomerBusiness from '../clienteGestor/negocios/Negocios';
import ClienteGestorResumen from '../clienteGestor/resumen/Resumen';

// Componentes de Aliados externos
import Aliados from '../aliadosExternos/inicio/Inicio';
import AddCandidate from '../aliadosExternos/addCandidate/addCandidate';
import ViewCandidates from '../aliadosExternos/viewCandidates/viewCandidates';
import ViewVacantes from '../aliadosExternos/viewVacantes/ViewVacante';

// Componentes de clientes
import Clientes from '../clients/home/Home';
import Tracing from '../clients/seguimientos/Seguimientos';
import AddSeguimiento from '../clients/seguimientos/AddSeguimiento';
import ViewSeguimiento from '../clients/seguimientos/ViewSeguimiento';

import TracingNuevo from '../clients/seguimientos-nuevo/Seguimientos';
import AddSeguimientoNuevo from '../clients/seguimientos-nuevo/AddSeguimiento';
import ViewSeguimientoNuevo from '../clients/seguimientos-nuevo/ViewSeguimiento';


import Linked from '../clients/vinculados/Linked';
import Vacantes from '../clients/vacantes/Vacantes';

// Componentes de seleccion
import Resumen from '../seleccion/resumen/Resumen';
import ConsolidadoSeleccion from '../seleccion/consolidado/consolidado';
import CiudadesSeleccion from '../seleccion/ciudades/Ciudades';
import AliadosSeleccionFiltro from '../seleccion/aliados/Aliados';
import AliadosExternosSeleccion from '../seleccion/aliadosExternos/AliadosExternos';
import ResumenGeneral from '../seleccion/resumenSeleccion/ResumenSeleccion';
import ResumenDetallado from '../seleccion/resumenDetallado/ResumenDetallado';
import AprobadosSeleccion from '../seleccion/aprobados/Aprobados';
import AprobadosDetalleSeleccion from '../seleccion/aprobadosDetalle/AprobadosDetalle';
import AsistieronSeleccion from '../seleccion/asistieron/Asistieron';
import NoAsistieronSeleccion from '../seleccion/noAsistieron/noAsistieron';
import NoContestaronSeleccion from '../seleccion/noContesto/noContesto';
import VinculadosSeleccion from '../seleccion/vinculados/vinculados';
import DesvincularSeleccion from '../seleccion/desvincular/desvincular';
import Candidatos from '../seleccion/candidatos/Candidatos';
import NoCitadosSeleccion from '../seleccion/noCitados/NoCitados';
import NoPasaronProceso from '../seleccion/noPasaronProceso/NoPasaronProceso';
import NoContinuaProceso from '../seleccion/noContinuaProceso/NoContinuaProceso';
import PendientesSeleccion from '../seleccion/pendientes/Pendientes';
import Remitidos from '../seleccion/remitidos/Remitidos';
import EntrevistaSeleccion from '../seleccion/entrevista/Entrevista';
import CiudadesResumenSeleccion from '../seleccion/resumenDetallado/ciudades/Ciudades';
import AliadosSeleccionResumenFiltro from '../seleccion/resumenDetallado/aliados/Aliados';
import ViewHojaVidaSeleccion from '../seleccion/hojaVida/viewHojaVida/ViewHojaVida';
import HojaVidaSeleccion from '../seleccion/hojaVida/addHojaVida/HojaVida';

// Componentes aprendiz
import EntrevistaAprendiz from '../aprendiz/entrevista/Entrevista';
import ResumenDetalladoAprendiz from '../aprendiz/resumenDetallado/ResumenDetallado';
import RelacionamientoAprendiz from '../aprendiz/relacionamiento/Relacionamiento';
import CiudadesResumenAprendiz from '../aprendiz/resumenDetallado/ciudades/Ciudades';
import AliadosAprendizResumenFiltro from '../aprendiz/resumenDetallado/aliados/Aliados';
import ResumenGeneralAprendiz from '../aprendiz/resumenSeleccion/ResumenSeleccion';
import RemitidosAprendiz from '../aprendiz/remitidos/Remitidos';
import CandidatosAprendiz from '../aprendiz/candidatos/Candidatos';
import AprobadosAprendiz from '../aprendiz/aprobados/Aprobados';
import NoPasaronProcesoAprendiz from '../aprendiz/noPasaronProceso/NoPasaronProceso';
import NoCitadosAprendiz from '../aprendiz/noCitados/NoCitados';
import AsistieronAprendiz from '../aprendiz/asistieron/Asistieron';
import PendientesAprendiz from '../aprendiz/pendientes/Pendientes';
import ViewHojaVidaAprendiz from '../aprendiz/hojaVida/viewHojaVida/ViewHojaVida';

// Componentes practicante

import EntrevistaPracticante from '../practicante/entrevista/Entrevista';
import AcompanamientoPracticante from '../practicante/acompanamiento/Acompanamiento';
import ResumenDetalladoPracticante from '../practicante/resumenDetallado/ResumenDetallado';
import CiudadesResumenPracticante from '../practicante/resumenDetallado/ciudades/Ciudades';
import AliadosPracticanteResumenFiltro from '../practicante/resumenDetallado/aliados/Aliados';
import ResumenGeneralPracticante from '../practicante/resumenSeleccion/ResumenSeleccion';
import RemitidosPracticante from '../practicante/remitidos/Remitidos';
import CandidatosPracticante from '../practicante/candidatos/Candidatos';
import AprobadosPracticante from '../practicante/aprobados/Aprobados';
import NoPasaronProcesoPracticante from '../practicante/noPasaronProceso/NoPasaronProceso';
import NoCitadosPracticante from '../practicante/noCitados/NoCitados';
import AsistieronPracticante from '../practicante/asistieron/Asistieron';
import PendientesPracticante from '../practicante/pendientes/Pendientes';
import ViewHojaVidaPracticante from '../practicante/hojaVida/viewHojaVida/ViewHojaVida';
import TalleresPracticante from '../practicante/acompanamiento/talleres/Talleres';
import AddTalleresPracticante from '../practicante/acompanamiento/talleres/addTalleres/addTalleres';

// Componenete acompañamiento
import SeguimientoAcompanamiento from '../acompanamiento/seguimiento/Seguimiento';
import SeguimientoAcompanamientoNuevo from '../acompanamiento/seguimiento-nuevo/Seguimiento';



import TalleresAcompanamiento from '../acompanamiento/talleres/Talleres';
import AddTalleres from '../acompanamiento/talleres/addTalleres/addTalleres';
import EditTalleres from '../acompanamiento/talleres/addTalleres/editTalleres';
import TalleresFoco from '../acompanamiento/talleresFoco/TalleresFoco';
import AddTalleresFoco from '../acompanamiento/talleresFoco/addTalleres/addTalleresFoco';
import EditTalleresFoco from '../acompanamiento/talleresFoco/addTalleres/editTalleresFoco';

import AddSeleccionAcom from '../acompanamiento/seguimiento/addSeleccion/addSeguimiento';
import ViewSeleccionAcom from '../acompanamiento/seguimiento/addSeleccion/ViewSeguimiento';


import AddSeleccionAcomNuevo from '../acompanamiento/seguimiento-nuevo/addSeleccion/addSeguimiento';
import ViewSeleccionAcomNuevo from '../acompanamiento/seguimiento-nuevo/addSeleccion/ViewSeguimiento';


import AsoseriasAcompanamiento from '../acompanamiento/asesorias/Asesorias';
import AsesoriasEspecializadas from '../acompanamiento/asesorias/AsesoriasEspeciales';
import AddAsesoriasAcompanamiento from '../acompanamiento/asesorias/addAsesoria/AddAsesoria';
import AddEmpleadoAcompanamiento from '../acompanamiento/asesorias/colaborador/Colaborador';
import Consolidados from '../acompanamiento/asesorias/consolidados/Consolidados';
import ConsolidadoGestor from '../acompanamiento/asesorias/consolidadoGestor/consolidadoGestor';
import RemisionesAcompanamiento from '../acompanamiento/asesorias/remisiones/Remisiones';
import EditRemisiones from '../acompanamiento/asesorias/remisiones/EditRemisiones';
import AddJefeAcompanamiento from '../acompanamiento/asesorias/jefe/Jefe';
import ResumenDetalladoAcompanamiento from '../acompanamiento/resumenDetallado/ResumenDetallado';
import CiudadesResumenAcompanamiento from '../acompanamiento/resumenDetallado/ciudades/Ciudades';
import AliadosAcompanamientoResumenFiltro from '../acompanamiento/resumenDetallado/aliados/Aliados';
import AprobadosAcompanamiento from '../acompanamiento/status/aprobados/Aprobados';
import NoContestaronAcompanamiento from '../acompanamiento/status/noContesto/noContesto';
import VinculadosAcompanamiento from '../acompanamiento/status/vinculados/vinculados';
import DesvincularAcompanamiento from '../acompanamiento/status/desvincular/desvincular';
import NoPasaronProcesoAcompanamiento from '../acompanamiento/status/noPasaronProceso/NoPasaronProceso';
import EntrevistaAcompanamiento from '../acompanamiento/status/entrevista/Entrevista';
import ViewHojaVidaAcompanamiento from '../acompanamiento/hojaVida/viewHojaVida/ViewHojaVida';
import Graphics from '../relacionamiento/graphics/graphics';
import AsignarEntrevista from '../aprendiz/entrevista/asignarEntrevista/asignarEntrevista';
import ActualizarEntrevista from '../aprendiz/entrevista/actualizarEntrevista/actualizarEntrevista';
import PendientesContratacion from '../aprendiz/entrevista/pendientesContratacion/pendientes';
import TablaRelacionamiento from '../aprendiz/relacionamiento/tablaRelacionamiento/tablaRelacionamiento';
import AsignarEntrevistaPracticante from '../practicante/entrevista/asignarEntrevista/asignarEntrevista';
import Informes from '../practicante/informes/Informes';
import Asesoria from '../practicante/asesoria/Asesoria';
import GraficaPracticante from '../practicante/graphics/graficaPracticante';
import GraficaAprendiz from '../aprendiz/grafica/GraficaAprendiz';
import GraficaAcompanamiento from '../acompanamiento/graficaAcompanamiento/GraficaAcompanamiento';
import AprobadosRelacionamiento from '../clienteGestor/resumen/aprobadosProcesoVinculacion/aprobados';
import NoContestoRelacionamiento from '../clienteGestor/resumen/no-contestoProcesoVinculacion/nocontesto';
import EntrevistasRelacionamiento from '../clienteGestor/resumen/entrevistasProcesoVinculacion/entrevistas';
import PendientesContrato from '../clienteGestor/resumen/pendientesContratacion/pendientes';
import NoContinuaRelacionamiento from '../clienteGestor/resumen/no-continuaProceoVinculacion/nocontinua';
import VinculadosRelacionamiento from '../clienteGestor/resumen/vinculadosProcesoVinculacion/vinculados';
import DesvinculadosRelacionamiento from '../clienteGestor/resumen/desvinculadosProcesoVinculacion/desvinculados';
// Listado usuarios
import ListaUsuarios from '../listaUsuarios/ListaUsuarios';

//Emprendimientos
import viewEmprendimiento from '../hvEmprendimiento/viewEmprendimiento/viewEmprendimiento';
import addEmpren from '../aliadosExternos/addEmprendimiento/addEmpren';
import TableEmprendimiento from '../aliadosExternos/viewEmprendimiento/tableEmprendimiento';
import GraphicsEmprend from '../relacionamiento/graphicsEmprend/graphicsEmprend';
import reportEmpred from '../relacionamiento/reportEmpred/reportEmpred';


import EmprendimientosT from '../relacionamiento/emprendimientosT/EmprendimientosT';
import CursosTalleres from '../relacionamiento/cursosTalleres/CursosTalleres';
import AddCursosTalleres from '../relacionamiento/cursosTalleres/addCursosTalleres/addCursosTalleres';
import EditCursosTalleres from '../relacionamiento/cursosTalleres/addCursosTalleres/editCursosTalleres';
import Departments from '../ciudades/Departments';
import CitiesDepart from '../ciudades/CitiesDepart';

import Sectores from '../emprendimientos/Sectores';
import SectoresDetalle from '../emprendimientos/SectoresEmprendimientosT';


function Dashboard({ module }) {
	let { path, url } = useRouteMatch();
	return (
		<div className="row backgroundDashboard">
			<div className="col-2 containerLeft" style={{overflowY: 'scroll', height: '100vh', backgroundColor: '#1e455f'}}>
				<Menu module={module} />
			</div>
			<div className="col-2"></div>

			<div className="col-10 containerRight">
				<Route exact path={`${path}/vacantesRS`} component={VacantesRS} />

				{/* Rutas emprendimientos */}
				<Route exact path={`${path}/addEmprendimiento`} component={addEmpren} />
				<Route exact path={`${path}/viewEmprendimiento`} component={TableEmprendimiento} />
				<Route exact path={`${path}/detalleEmprendimiento/:emprend`} component={viewEmprendimiento} />
				<Route exact path={`${path}/graphicsEmprend`} component={GraphicsEmprend} />
				<Route exact path={`${path}/reportEmpred`} component={reportEmpred} />
				
				<Route exact path={`${path}/departments/city/:name/emprendimientos`} component={EmprendimientosT} />
				<Route exact path={`${path}/cursos`} component={CursosTalleres} />
				<Route exact path={`${path}/cursos/new`} component={AddCursosTalleres} />
				<Route exact path={`${path}/cursos/edit/:id`} component={EditCursosTalleres} />
				<Route exact path={`${path}/departments`} component={Departments} />
				<Route exact path={`${path}/sectores`} component={Sectores} />
				<Route exact path={`${path}/sectores/detalle/:name`} component={SectoresDetalle} />
				<Route exact path={`${path}/departments/city/:name`} component={CitiesDepart} />

				{/* Listado usuarios */}
				<Route exact path={`${path}/listadoUsuarios`} component={ListaUsuarios} />


				{/* Rutas de aliados */}
				<Route exact path={`${path}/aliados`} component={Aliados} />
				<Route exact path={`${path}/addCandidate`} component={AddCandidate} />
				<Route exact path={`${path}/viewCandidate`} component={ViewCandidates} />
				<Route exact path={`${path}/viewVacante`} component={ViewVacantes} />

				{/* Rutas de clientes */}
				<Route exact path={`${path}/clientes`} component={Clientes} />

				<Route exact path={`${path}/seguimientos`} component={Tracing} />
				<Route exact path={`${path}/seguimientos/agregar/:candidate`} component={AddSeguimiento} />
				<Route exact path={`${path}/seguimientos/editar/:candidate`} component={ViewSeguimiento} />

				<Route exact path={`${path}/seguimientos-nuevo`} component={TracingNuevo} />
				<Route exact path={`${path}/seguimientos-nuevo/agregar/:candidate`} component={AddSeguimientoNuevo} />
				<Route exact path={`${path}/seguimientos-nuevo/editar/:candidate`} component={ViewSeguimientoNuevo} />

				<Route exact path={`${path}/vinculados`} component={Linked} />
				<Route exact path={`${path}/vacantes`} component={Vacantes} />

				{/* Rutas de relacionamiento */}
				<Route exact path={`${path}/customer/cities/business/summary/:id/aprobados`} component={AprobadosRelacionamiento} />
				<Route exact path={`${path}/customer/cities/business/summary/:id/no-contesto`} component={NoContestoRelacionamiento} />
				<Route exact path={`${path}/customer/cities/business/summary/:id/entrevistas`} component={EntrevistasRelacionamiento} />
				<Route exact path={`${path}/customer/cities/business/summary/:id/pendientes`} component={PendientesContrato} />
				<Route exact path={`${path}/customer/cities/business/summary/:id/no-continuan-proceso`} component={NoContinuaRelacionamiento} />
				<Route exact path={`${path}/customer/cities/business/summary/:id/vinculados/:vins`} component={VinculadosRelacionamiento} />
				<Route exact path={`${path}/customer/cities/business/summary/:id/desvinculados`} component={DesvinculadosRelacionamiento} />

				<Route exact path={`${path}/graphics`} component={Graphics} />
				<Route exact path={`${path}/relacionamiento`} component={GeneralData} />
				<Route exact path={`${path}/customer/cities`} component={CustomerCities} />
				<Route exact path={`${path}/customer/cities/business`} component={CustomerBusiness} />
				<Route exact path={`${path}/customer/cities/business/summary/:id`} component={ClienteGestorResumen} />
				<Route exact path={`${path}/cities`} component={Ciudades} />
				<Route exact path={`${path}/cities/aliados/:city`} component={AliadosFiltro} />
				<Route exact path={`${path}/cities/aliados/remitted/:ally`} component={AliadosExternos} />
				<Route exact path={`${path}/cities/aliados/remitted/:ally/removed`} component={Eliminados} />
				<Route exact path={`${path}/viewCandidate/:candidate`} component={HojaVida} />
				<Route exact path={`${path}/calendar`} component={Cronogramas} />
				<Route exact path={`${path}/reentry`} component={Reingreso} />
				{/* <Route exact path={`${path}/advisory`} component={Asesorias}/> */}
				<Route exact path={`${path}/calendar/records`} component={Registros} />
				<Route exact path={`${path}/calendar/records/add`} component={AddCronogramaRegistros} />
				<Route exact path={`${path}/calendar/aliados`} component={CronogramasAliados} />
				<Route exact path={`${path}/calendar/aliados/add`} component={AddCronogramaSeguimientos} />
				<Route exact path={`${path}/calendar/vinculados`} component={Vinculados} />

				<Route exact path={`${path}/seleccion/viewCandidate/:candidate`} component={ViewHojaVidaSeleccion} />
				<Route exact path={`${path}/seleccion/addCandidate`} component={HojaVidaSeleccion} />

				<Route exact path={`${path}/resumen/:ally`} component={Resumen} />
				<Route exact path={`${path}/resumen-general`} component={ConsolidadoSeleccion} />
				<Route exact path={`${path}/resumen-detallado/:ally`} component={ResumenDetallado} />

				<Route exact path={`${path}/seleccion`} component={ResumenGeneral} />

				<Route exact path={`${path}/filter/cities`} component={CiudadesSeleccion} />
				<Route exact path={`${path}/filter/cities/aliados/:city`} component={AliadosSeleccionFiltro} />
				<Route exact path={`${path}/filter/cities/aliados/remitted/:ally`} component={AliadosExternosSeleccion} />

				<Route exact path={`${path}/filter/summary/cities`} component={CiudadesResumenSeleccion} />
				<Route exact path={`${path}/filter/summary/cities/aliados/:city`} component={AliadosSeleccionResumenFiltro} />

				<Route exact path={`${path}/aprobados/:ally/:substatus`} component={AprobadosSeleccion} />
				<Route exact path={`${path}/aprobados-detalle/:ally/:substatus`} component={AprobadosDetalleSeleccion} />
				<Route exact path={`${path}/asistieron/:ally/:substatus`} component={AsistieronSeleccion} />
				<Route exact path={`${path}/no-asistieron/:ally/:substatus`} component={NoAsistieronSeleccion} />
				<Route exact path={`${path}/candidatos/:ally/:substatus`} component={Candidatos} />
				<Route exact path={`${path}/no-citados/:ally/:substatus`} component={NoCitadosSeleccion} />
				<Route exact path={`${path}/no-pasaron-proceso/:ally/:substatus`} component={NoPasaronProceso} />
				<Route exact path={`${path}/no-continua-proceso/:ally/:substatus`} component={NoContinuaProceso} />
				<Route exact path={`${path}/pendientes/:ally/:substatus`} component={PendientesSeleccion} />
				<Route exact path={`${path}/remitidos/:ally/:substatus`} component={Remitidos} />
				<Route exact path={`${path}/entrevista/:ally/:substatus`} component={EntrevistaSeleccion} />
				<Route exact path={`${path}/no-contestaron/:ally/:substatus`} component={NoContestaronSeleccion} />
				<Route exact path={`${path}/vinculados/:ally/:substatus`} component={VinculadosSeleccion} />
				<Route exact path={`${path}/desvincular/:ally/:substatus`} component={DesvincularSeleccion} />

				{/* Rutas del aprendiz */}
				<Route exact path={`${path}/aprendiz/grafica`} component={GraficaAprendiz} />
				<Route exact path={`${path}/aprendiz/tablaRelacionamiento`} component={TablaRelacionamiento} />
				<Route exact path={`${path}/aprendiz/asignarEntrevista`} component={AsignarEntrevista} />
				<Route exact path={`${path}/aprendiz/actualizarEntrevista`} component={ActualizarEntrevista} />
				<Route exact path={`${path}/aprendiz/pendientes`} component={PendientesContratacion} />
				<Route exact path={`${path}/aprendiz`} component={EntrevistaAprendiz} />
				<Route exact path={`${path}/aprendiz/relacionamiento`} component={RelacionamientoAprendiz} />
				<Route exact path={`${path}/aprendiz/resumen-general`} component={ResumenGeneralAprendiz} />
				<Route exact path={`${path}/aprendiz/filter/summary/cities`} component={CiudadesResumenAprendiz} />
				<Route exact path={`${path}/aprendiz/filter/summary/cities/aliados/:city`} component={AliadosAprendizResumenFiltro} />
				<Route exact path={`${path}/aprendiz/resumen-detallado/:ally`} component={ResumenDetalladoAprendiz} />
				<Route exact path={`${path}/aprendiz/remitidos/:ally/:substatus`} component={RemitidosAprendiz} />
				<Route exact path={`${path}/aprendiz/candidatos/:ally/:substatus`} component={CandidatosAprendiz} />
				<Route exact path={`${path}/aprendiz/aprobados/:ally/:substatus`} component={AprobadosAprendiz} />
				<Route exact path={`${path}/aprendiz/no-pasaron-proceso/:ally/:substatus`} component={NoPasaronProcesoAprendiz} />
				<Route exact path={`${path}/aprendiz/no-citados/:ally/:substatus`} component={NoCitadosAprendiz} />
				<Route exact path={`${path}/aprendiz/asistieron/:ally/:substatus`} component={AsistieronAprendiz} />
				<Route exact path={`${path}/aprendiz/pendientes:ally/:substatus`} component={PendientesAprendiz} />
				<Route exact path={`${path}/aprendiz/viewCandidate/:candidate`} component={ViewHojaVidaAprendiz} />

				{/* Rutas del practicante */}
				<Route exact path={`${path}/practicante/grafica`} component={GraficaPracticante} />
				<Route exact path={`${path}/practicante/asesoria`} component={Asesoria} />
				<Route exact path={`${path}/practicante/informes`} component={Informes} />
				<Route exact path={`${path}/practicante/asignarEntrevista`} component={AsignarEntrevistaPracticante} />
				<Route exact path={`${path}/entrevista`} component={EntrevistaAprendiz} />
				<Route exact path={`${path}/acompanamiento`} component={AcompanamientoPracticante} />
				<Route exact path={`${path}/practicante/resumen-general`} component={ResumenGeneralPracticante} />
				<Route exact path={`${path}/practicante/filter/summary/cities`} component={CiudadesResumenPracticante} />
				<Route exact path={`${path}/practicante/filter/summary/cities/aliados/:city`} component={AliadosPracticanteResumenFiltro} />
				<Route exact path={`${path}/practicante/resumen-detallado/:ally`} component={ResumenDetalladoPracticante} />
				<Route exact path={`${path}/practicante/remitidos/:ally/:substatus`} component={RemitidosPracticante} />
				<Route exact path={`${path}/practicante/candidatos/:ally/:substatus`} component={CandidatosPracticante} />
				<Route exact path={`${path}/practicante/aprobados/:ally/:substatus`} component={AprobadosPracticante} />
				<Route exact path={`${path}/practicante/no-pasaron-proceso/:ally/:substatus`} component={NoPasaronProcesoPracticante} />
				<Route exact path={`${path}/practicante/no-citados/:ally/:substatus`} component={NoCitadosPracticante} />
				<Route exact path={`${path}/practicante/asistieron/:ally/:substatus`} component={AsistieronPracticante} />
				<Route exact path={`${path}/practicante/pendientes:ally/:substatus`} component={PendientesPracticante} />
				<Route exact path={`${path}/practicante/viewCandidate/:candidate`} component={ViewHojaVidaPracticante} />
				<Route exact path={`${path}/practicante/Talleres`} component={TalleresPracticante} />
				<Route exact path={`${path}/practicante/Talleres/new`} component={AddTalleresPracticante} />

				{/* Rutas del acompañamiento */}
				<Route exact path={`${path}/grafica`} component={GraficaAcompanamiento} />
				<Route exact path={`${path}/talleres`} component={TalleresAcompanamiento} />
				<Route exact path={`${path}/talleres/new`} component={AddTalleres} />
				<Route exact path={`${path}/talleres/edit/:id`} component={EditTalleres} />
				<Route exact path={`${path}/talleresFoco`} component={TalleresFoco} />
				<Route exact path={`${path}/talleresFoco/new`} component={AddTalleresFoco} />
				<Route exact path={`${path}/talleresFoco/edit/:id`} component={EditTalleresFoco} />

				<Route exact path={`${path}/acompanamiento/seguimiento/:min/:max/:month/:year/new/:idCan`} component={AddSeleccionAcom} />
				<Route exact path={`${path}/acompanamiento/seguimiento/:min/:max/:month/:year/view/:idCan`} component={ViewSeleccionAcom} />
				<Route exact path={`${path}/acompanamiento/seguimiento/:min/:max/:month/:year`} component={SeguimientoAcompanamiento} />

				<Route exact path={`${path}/acompanamiento/seguimiento-nuevo/:min/:max/:month/:year/new/:idCan`} component={AddSeleccionAcomNuevo} />
				<Route exact path={`${path}/acompanamiento/seguimiento-nuevo/:min/:max/:month/:year/view/:idCan`} component={ViewSeleccionAcomNuevo} />
				<Route exact path={`${path}/acompanamiento/seguimiento-nuevo/:min/:max/:month/:year`} component={SeguimientoAcompanamientoNuevo} />

				<Route exact path={`${path}/acompanamiento/filter/summary/cities`} component={CiudadesResumenAcompanamiento} />
				<Route exact path={`${path}/acompanamiento/filter/summary/cities/aliados/:city`} component={AliadosAcompanamientoResumenFiltro} />
				<Route exact path={`${path}/acompanamiento/resumen-detallado/:ally`} component={ResumenDetalladoAcompanamiento} />
				<Route exact path={`${path}/status/aprobados/:ally/:substatus`} component={AprobadosAcompanamiento} />
				<Route exact path={`${path}/status/no-pasaron-proceso/:ally/:substatus`} component={NoPasaronProcesoAcompanamiento} />
				<Route exact path={`${path}/status/entrevista/:ally/:substatus`} component={EntrevistaAcompanamiento} />
				<Route exact path={`${path}/status/no-contestaron/:ally/:substatus`} component={NoContestaronAcompanamiento} />
				<Route exact path={`${path}/status/vinculados/:ally/:substatus`} component={VinculadosAcompanamiento} />
				<Route exact path={`${path}/status/desvinculados/:ally/:substatus`} component={DesvincularAcompanamiento} />
				<Route exact path={`${path}/acompanamiento/viewCandidate/:candidate`} component={ViewHojaVidaAcompanamiento} />
				<Route exact path={`${path}/advisory`} component={AsoseriasAcompanamiento} />
				<Route exact path={`${path}/advisory-special`} component={AsesoriasEspecializadas} />
				<Route exact path={`${path}/advisory/new`} component={AddAsesoriasAcompanamiento} />
				<Route exact path={`${path}/advisory/new/employee`} component={AddEmpleadoAcompanamiento} />
				<Route exact path={`${path}/advisory/new/consolidado`} component={Consolidados} />
				<Route exact path={`${path}/advisory/new/consolidado/gestor/:manageId/:name`} component={ConsolidadoGestor} />
				<Route exact path={`${path}/advisory/new/remisiones`} component={RemisionesAcompanamiento} />
				<Route exact path={`${path}/new/detalle-remision/:remi`} component={EditRemisiones} />
				<Route exact path={`${path}/new/employee/:advi`} component={AddEmpleadoAcompanamiento} />
				<Route exact path={`${path}/advisory/new/jefe`} component={AddJefeAcompanamiento} />
				<Route exact path={`${path}/new/jefe/:advi`} component={AddJefeAcompanamiento} />
				<Route exact path={`${path}/resumen-seguimientos`} component={ResumenSeguimiento} />
				<Route exact path={`${path}/resumen-seguimientos-nuevo`} component={ResumenSeguimientoNuevo} />

				
			</div>
		</div>
	);

}

export default Dashboard;