import React, { Component } from "react";
import {
 FormGroup,
 Input,
 Button,
 Modal,
 ModalHeader,
 ModalBody,
 ModalFooter,
 Table,
} from "reactstrap";
import Header from "../../header/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEmprendConsolidado } from "../../../redux/actions/summaryActions";
import {
 searchEmprendimientos,
 setSearchEmpren,
} from "../../../redux/actions/emprendActions";
import { getEmprendiAlerts } from "../../../redux/actions/alertActions";
import "./reportEmpred.styles.css";

//Componentes Propios
import GraficosSeleccion from "../../seleccion/consolidado/graficas/graficas";
import BarrasSeleccion from "../../seleccion/consolidado/barras/barras";
import TablaSeleccion from "../../seleccion/consolidado/tabla/tabla";
import { Link } from "react-router-dom";
import CustomAlertEmprendimiento from "../../alerts/alertsEmpren";

class reportEmpred extends Component {
 constructor(props) {
  super(props);
  this.state = {
   searchText: "",
   openModal: false,

   filterDate: "2025-02",
  };
 }

 componentDidMount = () => {
  this.props.getEmprendConsolidado();
  this.props.getEmprendiAlerts(new Date());
 };

 searchInfo = () => {
  this.toggleModal();
  this.props.searchEmprendimientos(this.state.searchText);
 };

 viewEmprendimiento = (idEmp) => {
  this.props.history.push(
   `/${this.props.modulePath}/detalleEmprendimiento/${idEmp}`
  );
 };

 closeClean = () => {
  this.toggleModal();
  this.props.setSearchEmpren([]);
 };

 toggleModal = () => {
  this.setState({
   openModal: !this.state.openModal,
  });
 };

 searchModal = () => {
  return (
   <Modal
    isOpen={this.state.openModal}
    toggle={this.closeClean}
    size={"lg"}>
    <ModalHeader toggle={this.closeClean}>Resultados búsqueda</ModalHeader>
    <ModalBody>
     {this.props.searchEmprend.length !== 0 && (
      <div>
       <Table
        striped
        responsive>
        <thead>
         <tr>
          <th>Id</th>
          <th>Nombre completo</th>
          <th>Sector</th>
          <th>Ciudad</th>
          <th>Estado</th>
          <th></th>
         </tr>
        </thead>
        <tbody>
         {this.props.searchEmprend.results.map((item) => {
          return (
           <tr key={item.id.toString()}>
            <th scope="row">{item.id}</th>
            <td>{item.fullName}</td>
            <td>{item.sector}</td>
            <td>{item.city}</td>
            <td>{item.stateName}</td>
            <td
             onClick={() => {
              this.viewEmprendimiento(item.id);
             }}
             style={{ cursor: "pointer" }}>
             <i
              className="fa fa-eye"
              style={{ color: "#17a2b8" }}
              aria-hidden="true"></i>
            </td>
           </tr>
          );
         })}
        </tbody>
       </Table>
      </div>
     )}
    </ModalBody>
    <ModalFooter>
     <Button
      color="secondary"
      onClick={this.closeClean}>
      Cerrar
     </Button>
    </ModalFooter>
   </Modal>
  );
 };

 handleChange = (e) => {
  this.setState({
   filterDate: e.target.value,
  });
  console.log(e.target.value);
 };

 render() {
  return (
   <>
    <Header />
    {this.searchModal()}
    <div className="col-12 bgResumenSeleccion">
     <div className="col-12">
      <div
       className="alert alert-danger"
       role="alert"
       style={{ display: "none" }}>
       Victimas nacionales ha ingresado 5 nuevos candidatos remitidos
      </div>
     </div>
     <div>
      <div style={{ position: "absolute", zIndex: 99, right: 10 }}>
       {this.props.alertRelacionamiento.results.length !== 0 && (
        <CustomAlertEmprendimiento
         color="info"
         msg={`Se han recibido ${this.props.alertRelacionamiento.results.length} nuevos emprendimientos`}
         data={this.props.alertRelacionamiento.results}
         history={this.props.history}
         modulePath={this.props.modulePath}
        />
       )}
      </div>
      <div className="customRow mb-5 text-left">
       <p>
        <strong className="titleCiudad text-left">
         Descarga Reportes Modulo Emprendimientos
        </strong>
       </p>
      </div>

      <div className="row">
       <div className="col-3"></div>

       <div
        className="col-9"
        style={{ textAlign: "right", paddingRight: 26 }}></div>
      </div>
      <div className="row  bgConsolidadoSeleccion">
       <div className="table-responsiveGraphics containerTable containerReports">
        <h4>Descarga Consolidado</h4>
        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd1/`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         {" "}
         <button className="btn btn-info">Emprendimientos</button>
        </a>

        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd2/`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         <button className="btn btn-info">Facturas</button>
        </a>

        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd3/`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         <button className="btn btn-info">Otras Ventas</button>
        </a>

        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd4/`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         <button className="btn btn-info">Cotizaciones</button>
        </a>
       </div>
       <br />

       <br />
       <div className="table-responsiveGraphics containerTable containerReports">
        <h4>Descarga Consolidado Mes y Año</h4>

        <FormGroup>
         <Input
          id="filterTime"
          type="month"
          value={this.state.filterDate}
          onChange={this.handleChange}
         />
         {/* <br></br>
                <button className="btn btn-info">Nuevo Seguimiento</button> */}
        </FormGroup>
        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd2/?year=${
          this.state.filterDate.split("-")[0]
         }&month=${this.state.filterDate.split("-")[1]}`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         <button className="btn btn-info">Facturas</button>
        </a>

        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd3/?year=${
          this.state.filterDate.split("-")[0]
         }&month=${this.state.filterDate.split("-")[1]}`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         <button className="btn btn-info">Otras Ventas</button>
        </a>

        <a
         href={`https://alianza.multimedialab.dev/UHJvamVjdA/ffurjdd4/?year=${
          this.state.filterDate.split("-")[0]
         }&month=${this.state.filterDate.split("-")[1]}`}
         target="_blank"
         style={{ display: "inline", marginLeft: 10 }}>
         <button className="btn btn-info">Cotizaciones</button>
        </a>
       </div>

       <br />
       <br />
      </div>
     </div>
    </div>
   </>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  modulePath: state.allyReducer.modulePath,
  emprendTotal: state.summariesReducer.emprendTotal,
  alertRelacionamiento: state.alertsReducer.alertRelacionamiento,
  alertRemitidos: state.alertsReducer.alertRemitidos,
  searchEmprend: state.emprendimientoReducer.searchEmprend,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  getEmprendConsolidado: bindActionCreators(getEmprendConsolidado, dispatch),
  getEmprendiAlerts: bindActionCreators(getEmprendiAlerts, dispatch),
  searchEmprendimientos: bindActionCreators(searchEmprendimientos, dispatch),
  setSearchEmpren: bindActionCreators(setSearchEmpren, dispatch),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(reportEmpred);
